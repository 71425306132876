.mdc-notched-outline {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  /*rtl:ignore*/
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  text-align: right;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */
  /*rtl:ignore*/
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  border-left: none;
  /* @noflip */
  /*rtl:ignore*/
  border-right: 1px solid;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__trailing {
  /* @noflip */
  /*rtl:ignore*/
  border-left: none;
  /* @noflip */
  /*rtl:ignore*/
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  border-left: 1px solid;
  /* @noflip */
  /*rtl:ignore*/
  border-right: none;
  /*rtl:end:ignore*/
}

.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 133.3333333333%;
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 0;
  /*rtl:end:ignore*/
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  display: none;
}