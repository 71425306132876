.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, sans-serif));
  font-size: 0.875rem;
  /* @alternate */
  font-size: var(--mdc-typography-button-font-size, 0.875rem);
  line-height: 2.25rem;
  /* @alternate */
  line-height: var(--mdc-typography-button-line-height, 2.25rem);
  font-weight: 500;
  /* @alternate */
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: 0.0892857143em;
  /* @alternate */
  letter-spacing: var(--mdc-typography-button-letter-spacing, 0.0892857143em);
  text-decoration: none;
  /* @alternate */
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: uppercase;
  /* @alternate */
  text-transform: var(--mdc-typography-button-text-transform, uppercase);
  position: relative;
}
.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -12px
    );
  width: calc(
      100% + -8px
    );
  margin-top: -2px;
  z-index: 2;
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused.mdc-tab--active .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus.mdc-tab--active .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
  pointer-events: none;
  border: 2px solid transparent;
  border-radius: 8px;
  box-sizing: content-box;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(
      100% + -8px
    );
  width: calc(
      100% + -8px
    );
  z-index: 2;
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring {
    border-color: CanvasText;
  }
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
  content: "";
  border: 2px solid transparent;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 50%;
  /* @noflip */
  /*rtl:ignore*/
  left: 50%;
  /* @noflip */
  /*rtl:ignore*/
  transform: translate(-50%, -50%);
  height: calc(100% + 4px);
  width: calc(100% + 4px);
}
@media screen and (forced-colors: active) {
  .mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__focus-ring::after, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__focus-ring::after {
    border-color: CanvasText;
  }
}

.mdc-tab__content {
  position: relative;
}

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
}
.mdc-tab--active .mdc-tab__icon {
  color: #6200ee;
  /* @alternate */
  color: var(--mdc-theme-primary, #6200ee);
  fill: currentColor;
}

.mdc-tab {
  background: none;
}

.mdc-tab {
  min-width: 90px;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}
.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label {
  transition: 150ms color linear;
  display: inline-block;
  line-height: 1;
  z-index: 2;
}

.mdc-tab__icon {
  transition: 150ms color linear;
  z-index: 2;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mdc-tab--stacked .mdc-tab__text-label {
  padding-top: 6px;
  padding-bottom: 4px;
}

.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 8px;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 0;
}
[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /*rtl:begin:ignore*/
  /* @noflip */
  /*rtl:ignore*/
  padding-left: 0;
  /* @noflip */
  /*rtl:ignore*/
  padding-right: 8px;
  /*rtl:end:ignore*/
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-tab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-tab .mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 1);
}
.mdc-tab .mdc-tab__ripple::after {
  z-index: 0;
  /* @alternate */
  z-index: var(--mdc-ripple-z-index, 0);
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  top: 0;
  /* @noflip */
  /*rtl:ignore*/
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-tab.mdc-ripple-upgraded--unbounded .mdc-tab__ripple::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  /*rtl:ignore*/
  left: var(--mdc-ripple-left, 0);
}
.mdc-tab.mdc-ripple-upgraded--foreground-activation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-tab.mdc-ripple-upgraded--foreground-deactivation .mdc-tab__ripple::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab .mdc-tab__ripple::before,
.mdc-tab .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  /*rtl:ignore*/
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-tab.mdc-ripple-upgraded .mdc-tab__ripple::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-tab .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after {
  background-color: #6200ee;
  /* @alternate */
  background-color: var(--mdc-ripple-color, var(--mdc-theme-primary, #6200ee));
}
.mdc-tab:hover .mdc-tab__ripple::before, .mdc-tab.mdc-ripple-surface--hover .mdc-tab__ripple::before {
  opacity: 0.04;
  /* @alternate */
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-tab.mdc-ripple-upgraded--background-focused .mdc-tab__ripple::before, .mdc-tab:not(.mdc-ripple-upgraded):focus .mdc-tab__ripple::before {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-tab:not(.mdc-ripple-upgraded) .mdc-tab__ripple::after {
  transition: opacity 150ms linear;
}
.mdc-tab:not(.mdc-ripple-upgraded):active .mdc-tab__ripple::after {
  transition-duration: 75ms;
  opacity: 0.12;
  /* @alternate */
  opacity: var(--mdc-ripple-press-opacity, 0.12);
}
.mdc-tab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: var(--mdc-ripple-press-opacity, 0.12);
}

.mdc-tab__ripple {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  will-change: transform, opacity;
}