//
// Copyright 2018 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use '@material/theme/theme-color';

$icon-size: 24px !default;
$height: 48px !default;
$stacked-height: 72px !default;
$min-width: 90px !default;
$horizontal-padding: 24px !default;
$text-label-opacity: 0.6 !default;
$icon-opacity: 0.54 !default;
$text-label-color-default: rgba(
  theme-color.prop-value(on-surface),
  $text-label-opacity
) !default;
$icon-color-default: rgba(
  theme-color.prop-value(on-surface),
  $icon-opacity
) !default;
$text-label-color-active: primary !default;
$icon-color-active: primary !default;
$ripple-target: '.mdc-tab__ripple';
