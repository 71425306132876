//
// Copyright 2017 Google Inc.
//
// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:
//
// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.
//

@use '@material/theme/theme-color';

// Colors
$title-ink-color: theme-color.prop-value(on-surface) !default;
$subtitle-ink-color: theme-color.prop-value(on-surface) !default;
$item-inactive-ink-color: theme-color.prop-value(on-surface) !default;
$item-activated-ink-color: theme-color.prop-value(primary) !default;
$divider-color: theme-color.prop-value(on-surface) !default;
$surface-fill-color: surface !default;

// Opacity
$title-ink-opacity: theme-color.text-emphasis(high) !default;
$subtitle-ink-opacity: theme-color.text-emphasis(medium) !default;
$item-inactive-icon-ink-opacity: theme-color.text-emphasis(medium) !default;
$item-inactive-text-ink-opacity: theme-color.text-emphasis(high) !default;
$item-active-icon-ink-opacity: 1 !default;
$item-active-text-ink-opacity: theme-color.text-emphasis(high) !default;
$divider-opacity: 0.12 !default;

// Widths
$width: 256px !default;
$list-item-spacing: 4px !default;
$surface-padding: 16px !default;
$shape-radius: large !default;
$item-shape-radius: small !default;

// Animations
$animation-enter: 250ms !default;
$animation-exit: 200ms !default;

// Scrim
$modal-scrim-color: theme-color.prop-value(on-surface) !default;
$modal-scrim-opacity: 0.32 !default;

$z-index: 6 !default;
$modal-elevation: 16 !default;
